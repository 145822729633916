<template>
  <div :class="$vuetify.breakpoint.mobile ? 'secondary' : 'halfcolor-gradient'" class="position-relative">
    <img src="@/assets/images/page-img/Vector_14.png" class="position-absolute bottom-0 start-0">
  <section class="container-prosker">
    <!-- <div id="circle-small"></div>
    <div id="circle-medium"></div>
    <div id="circle-large"></div>
    <div id="circle-xlarge"></div>
    <div class="d-xl-block d-none" id="circle-xxlarge"></div> -->
      <v-row class="no-gutters">
        <v-col cols="12" md="6" class="text-left">
          <div
    :style="{
     backgroundImage: `url(${require('@/assets/images/page-img/Frame_15.png')})`,
     backgroundPosition: ' right top',
     backgroundRepeat: 'no-repeat',
  }"  >
          <div class="d-none d-md-block">
            <router-link :to="{ name: 'social.list' }" class="sign-in-logo position-absolute top-0 start-0 mt-4">
                  <img
                    src="../../assets/images/logo/logo-prosk-1.svg"
                    class="img-fluid "
                    alt="logo"
                  />
              </router-link>
              <div class="vh-100 d-flex flex-column position-relative justify-content-center">
                  <img v-if="chooseBanner" :src="require(`@/assets/images/page-img/${chooseBanner}`)" class="img-fluid" alt="banner" />
                  <div v-if="this.$route.name==='auth1.sign-in1'">
                          <h1 class="text-h3 mb-4 text-white font-weight-bold line-height mt-9 text-left">
                Hacé <span class="primary--text ">crecer</span> tu negocio
              </h1>
                    <p class="text-white text-left">
                      Más tiempo, más ingresos y más facilidad.
                    </p>
                  </div>
                  <h1  v-if="this.$route.name==='auth1.sign-up1'" class="text-h3 mb-4 text-white font-weight-bold line-height mt-9 text-left">
                Preparamos al <span class="primary--text ">Emprendedor del futuro</span>, te ayudamos en tu próximo paso.
              </h1>
              </div>
          </div>
          <div class="sign-in-from d-block d-md-none vh-100">
            <router-link :to="{ name: 'social.list' }" class="sign-in-logo mt-5 mb-5">
                  <img
                    src="../../assets/images/logo/logo-prosk-1.svg"
                    class="img-fluid "
                    alt="logo"
                  />
              </router-link>
            <v-card class="p-5 border-radius-medium">
            <router-view></router-view>
          </v-card>
          </div>
        </div>
        </v-col>
        <!-- <div class="text-center w-100 mt-5 d-block d-md-none">
          <a class="sign-in-logo mb-5" href="#">
            <img
              src="../../assets/images/logo/logo-prosk-1.svg"
              class="img-fluid"
              alt="logo"
            />
          </a>
        </div> -->
        <v-col md="6" class="bg-white align-center d-none d-md-flex">
          <div class="sign-in-from">
            <router-view></router-view>
          </div>
        </v-col>
      </v-row>
  </section>
 </div>
</template>
<script>
import { socialvue } from '@/config/pluginInit';

export default {
  name: 'AuthLayout1',
  mounted () {
    socialvue.index();
  },
  data () {
    return {
      bannerRoutes: ['auth1.sign-in1', 'auth1.sign-up1']
    };
  },
  computed: {
    chooseBanner () {
      const routeName = this.$route.name;
      switch (routeName) {
        case 'auth1.sign-in1':
          return 'signin_banner.png';
        case 'auth1.sign-up1':
          return 'signup_banner.png';
        default:
          return '';
      }
    }
  }
};
</script>
